/* eslint-disable no-await-in-loop */
import { memoize } from 'utils-decorators'

import { estimateL1Fee } from 'viem/op-stack'
import { getTransactionReceipt } from 'viem/actions'
import {
  Hash,
  getContract,
  encodeFunctionData,
  decodeFunctionResult,
} from 'viem'

import { FeeData } from '@/types/fees'

import { NetworkService, ERC20TokenService, Multicall3Service } from '@/services'

import {
  sleep,
  isExceedMaxRange,
  getGasLimitWithCheckBalance,
  AIRDROP_ALREADY_EXIST_MESSAGE,
} from '@/utils'

import { BI_ZERO } from '@/utils/constants/numbers'
import { fetchLogsInChunks } from '@/utils/getContractLogs'
import { stringToHash, toChecksumAddress } from '@/utils/crypto'

import {
  IAirdrop,
  IRecipients,
  IAirdropsMeta,
  GetClaimedMapResp,
  GetClaimedMapInput,
  GetClaimEventsAllInput,
  CreateGeneratorCalldata,
  updateCreateAirdropGasLimitInput,
  CheckApproveAndBalanceTokenInput,
} from './types'

import { transformAirdrop } from './utils'

import MerkleTree from '@/abi/MerkleProofAirdrop'

type MerkleTreeContract = ReturnType<typeof MerkleTreeService.getContract>

const EMPTY_OBJECT = Object.freeze({})
const COUNT_PER_REQUEST = 1000 // but request with 2x count_per
const NOT_DEPLOYED_CONTRACT_ERROR = 'Please check the contract instance; it seems that the contract has not been initialized for this network.'

const createGenerator = <T>(
  createCallData: CreateGeneratorCalldata<T>,
  list: T[],
) => (
    function* callsGenerator(limit: number) {
      const listInner = [...list]

      while (listInner.length) {
        const shortList = listInner.splice(0, limit)
        yield shortList.map((data) => createCallData(data))
      }
    }
  )


const createClaimedMulticall = <T>(
  multicall3Service: Multicall3Service,
  contract: MerkleTreeContract,
  list: T[],
  getCallData: (value: T) => [Hash, Hash],
) => {
  if (!contract?.address) {
    throw new Error('Contract is not defined')
  }
  const createCallData = (data: typeof list[number]) => {
    const callData = encodeFunctionData({
      abi: MerkleTree,
      functionName: 'isClaimed',
      args: getCallData(data),
    })
    return {
      callData,
      allowFailure: false,
      target: contract.address,
    }
  }

  const decoder = (data: Hash) => {
    const result = decodeFunctionResult({
      data,
      abi: MerkleTree,
      functionName: 'isClaimed',
    })
    return result
  }

  return multicall3Service.aggregateMulticallBatchGenerator(
    createGenerator(createCallData, list),
    // await contract.estimateGas.isClaimed(hash, recipients[0][0])
    29667,
    decoder,
  )
}

const createClaimedMulticallByRecipients = (
  multicall3Service: Multicall3Service,
  contract: MerkleTreeContract,
  recipients: IRecipients,
  airdropName: string,
) => {
  const hash = stringToHash(airdropName)

  const getCallData = ([recipient]: typeof recipients[number]): [Hash, Hash] => [
    hash,
    toChecksumAddress(recipient),
  ]

  return createClaimedMulticall(
    multicall3Service,
    contract,
    recipients,
    getCallData,
  )
}

const createClaimedMulticallByAirdropNames = (
  multicall3Service: Multicall3Service,
  contract: MerkleTreeContract,
  recipient: Hash,
  airdropNames: string[],
) => {
  const address = toChecksumAddress(recipient)

  const getCallData = (airdropName: typeof airdropNames[number]): [Hash, Hash] => [
    stringToHash(airdropName),
    address,
  ]

  return createClaimedMulticall(
    multicall3Service,
    contract,
    airdropNames,
    getCallData,
  )
}

const createAirdropsMulticall = (
  multicall3Service: Multicall3Service,
  contract: MerkleTreeContract,
  list: string[],
) => {
  if (!contract?.address) {
    throw new Error('Contract is not defined')
  }

  const createCallData = (data: typeof list[number]) => {
    const callData = encodeFunctionData({
      abi: MerkleTree,
      functionName: 'airdrops',
      args: [stringToHash(data)],
    })

    return {
      callData,
      target: contract.address,
      allowFailure: false,
    }
  }

  const decoder = (data: Hash) => {
    const result = decodeFunctionResult({
      data,
      abi: MerkleTree,
      functionName: 'airdrops',
    })

    return result as unknown as IAirdrop
  }

  return multicall3Service.aggregateMulticallBatchGenerator(
    createGenerator(createCallData, list),
    // await contract.estimateGas.airdrops(hash)
    40062,
    decoder,
  )
}

export class MerkleTreeService {
  public currentFee: string | null = null

  public gasLimit: string | null = null

  public createAirdropFee: string | null = null

  @memoize()
  public static getContract(chainId: number) {
    const networkService = new NetworkService(chainId)
    if (!networkService.settings) {
      return null
    }

    const { address } = networkService.settings.contracts.multisenderMerkle
    if (!address) {
      return null
    }

    const client = networkService.rpcProvider
    if (!client) {
      return null
    }

    return getContract({ address, abi: MerkleTree, client })
  }

  constructor(
    private chainId: number,
  ) {}

  private get settings() {
    const networkService = new NetworkService(this.chainId)
    const settings = networkService.settings?.contracts.multisenderMerkle
    return settings as NonNullable<typeof settings>
  }

  private get contract() {
    const contract = MerkleTreeService.getContract(this.chainId)
    return contract
  }

  public async updateCurrentFee() {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }
    const currentFee = await this.contract.read.fee()
    this.currentFee = currentFee.toString()

    return this.currentFee
  }

  public async updateCreateAirdropGasLimit(payload: updateCreateAirdropGasLimitInput) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const currentFee = await this.updateCurrentFee()

    const args = [
      payload.publicName,
      payload.treeRoot,
      payload.tokenAddress,
      BigInt(payload.total),
    ] as const

    const overrides = {
      value: BigInt(currentFee),
      account: payload.ethAccount,
    }

    const estimateGas = await this.contract.estimateGas.createAirdrop(args, overrides).catch(() => BI_ZERO)
    this.gasLimit = estimateGas.toString()
  }

  private async prepareCreateAirdrop(
    publicName: string,
    treeRoot: Hash,
    tokenAddress: Hash,
    total: string,
    ethAccount: Hash,
  ) {
    const args = [
      publicName,
      treeRoot,
      tokenAddress,
      BigInt(total),
    ] as const

    const currentFee = await this.updateCurrentFee()

    return {
      args,
      currentFee,

      publicName,
      treeRoot,
      tokenAddress,
      total,
      ethAccount,
    }
  }

  public async checkCreateAirdrop(...payload:
    Parameters<typeof this.prepareCreateAirdrop>) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const {
      args,
      total,
      ethAccount,
      currentFee,
      tokenAddress,
    } = await this.prepareCreateAirdrop(...payload)

    // before send transaction - test approve and balance of owner
    const { contract: tokenContract } = ERC20TokenService.buildClass(tokenAddress, this.chainId)

    if (!tokenContract) {
      throw new Error('Please init token contract')
    }

    try {
      const airdrop = await this.getAirdrop(args[0], 6)

      if (airdrop) {
        const error = new Error(AIRDROP_ALREADY_EXIST_MESSAGE)
        return Promise.reject(error)
      }
    } catch {
      // do nothing
    }


    await this.contract.estimateGas.createAirdrop(args, { value: BigInt(currentFee), account: ethAccount })
    await tokenContract.estimateGas.transferFrom(
      [
        ethAccount,
        this.contract.address,
        BigInt(total),
      ],
      { account: this.contract.address },
    )

    return true
  }

  public async createAirdrop(
    argsPayload: readonly [string, Hash, Hash, string, Hash],
    feeData: FeeData,
    isInjectedWallet: boolean,
  ) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const { args, ethAccount, currentFee } = await this.prepareCreateAirdrop(...argsPayload)

    const { rpcProvider: client, settings } = new NetworkService(this.chainId)
    if (!client || !client.chain?.id) {
      throw new Error('Please init client')
    }

    const overrides = { value: BigInt(currentFee), account: ethAccount }

    const data = encodeFunctionData({
      args,
      abi: MerkleTree,
      functionName: 'createAirdrop',
    })

    const { gasLimit, gasParams } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.createAirdrop(args, overrides),
      settings?.network.opStack ? estimateL1Fee(client, {
        data,
        ...overrides,
        to: this.contract.address,
      }) : Promise.resolve(BI_ZERO),
      feeData,
      client,
      ethAccount,
      isInjectedWallet,
    )


    return {
      ...gasParams,
      data,
      value: BigInt(currentFee),
      to: this.contract.address,
      gas: BigInt(gasLimit.toString()),
    }
  }

  public async getAirdrop(airdropName: string, decimals: number) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const hash = stringToHash(airdropName)
    const [
      owner,
      root,
      tokenAddress,
      total,
      claimed,
      cancelled,
    ] = await this.contract.read.airdrops([hash])

    return total === BI_ZERO ? null : transformAirdrop({
      owner,
      root,
      total,
      claimed,
      cancelled,
      tokenAddress,
    }, decimals)
  }

  public async getAirdropEvent(airdropName: string, creator: Hash, fromBlock: number, toBlock?: number) {
    const { contract } = this
    if (!contract) {
      return undefined
    }

    const logs = await fetchLogsInChunks({
      chainId: this.chainId,
      fromBlock: BigInt(fromBlock),
      toBlock: toBlock ? BigInt(toBlock) : undefined,
      getEvents: (from: bigint, to: bigint) => contract.getEvents.AirdropCreated({ creator }, {
        toBlock: to,
        fromBlock: from,
      }),
    })

    if (logs) {
      const searchableEvent = logs.find((log) => log.args.airdropName === airdropName)
      return searchableEvent
    }
    return undefined
  }

  public async getCancelAirdropEvent(airdropName: string, fromBlock: number, toBlock?: number) {
    const { contract } = this
    if (!contract) {
      return undefined
    }

    const logs = await fetchLogsInChunks({
      chainId: this.chainId,
      fromBlock: BigInt(fromBlock),
      toBlock: toBlock ? BigInt(toBlock) : undefined,
      getEvents: (from: bigint, to: bigint) => contract.getEvents.AirdropCancelled({
        toBlock: to,
        fromBlock: from,
      }),
    })

    if (logs) {
      const searchableEvent = logs.find((el) => el.args.airdropName === airdropName)
      return searchableEvent
    }
    return undefined
  }

  public async getClaimEvent(airdropName: string, recipient: Hash, fromBlock: number, toBlock?: number) {
    const { contract } = this
    if (!contract) {
      return undefined
    }

    const [log] = await fetchLogsInChunks({
      chainId: this.chainId,
      fromBlock: BigInt(fromBlock),
      toBlock: toBlock ? BigInt(toBlock) : undefined,
      getEvents: (from: bigint, to: bigint) => contract.getEvents.Claim(
        { recipient, airdropName },
        { toBlock: to, fromBlock: from },
      ),
    })

    return log || undefined
  }

  public async checkClaimEvent(airdropName: string, recipient: Hash, txHashAirdrop: Hash) {
    const { rpcProvider: client } = new NetworkService(this.chainId)

    if (!client) {
      throw new Error('Please init client')
    }
    const [isClaimed, tx] = await Promise.all([
      this.getClaimed(airdropName, recipient),
      getTransactionReceipt(client, { hash: txHashAirdrop })
        .catch(() => ({ blockNumber: this.settings?.blockFrom as number })),
    ])

    // not have event
    if (!isClaimed) return void 0

    try {
      const event = await this.getClaimEvent(
        airdropName,
        recipient,
        Number(tx.blockNumber) - 10,
        Number(tx.blockNumber) + 10,
      )
      return event || EMPTY_OBJECT
    } catch {
      return EMPTY_OBJECT
    }
  }

  public async getClaimed(airdropName: string, recipient: Hash) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const hash = stringToHash(airdropName)

    const result = await this.contract.read.isClaimed([hash, recipient])
    return result
  }

  public async getClaimEventsAll(options: GetClaimEventsAllInput) {
    const { contract, settings } = this
    const { airdropName, blockNumber } = options

    if (!contract || !settings?.address) {
      return null
    }

    try {
      const logs = await fetchLogsInChunks({
        chainId: this.chainId,
        fromBlock: BigInt(blockNumber),
        getEvents: (from: bigint, to: bigint) => contract.getEvents.Claim({ airdropName }, {
          toBlock: to,
          fromBlock: from,
        }),
      })

      const eventsMap = logs.reduce<Record<Hash, { transactionHash: Hash }>>((acc, log) => {
        const { recipient } = log.args
        if (recipient) {
          acc[recipient] = { transactionHash: log.transactionHash }
        }
        return acc
      }, {})
      return eventsMap
    } catch (error) {
      if (isExceedMaxRange(error)) {
        return Promise.reject(error)
      }
      return null
    }
  }

  public async getClaimedMap(options: GetClaimedMapInput): Promise<GetClaimedMapResp> {
    if (!this.contract || !this.settings?.address) {
      return null
    }

    const { rpcProvider: client } = new NetworkService(this.chainId)

    if (!client) {
      throw new Error('Please init client')
    }

    const { airdropName, recipients, txHash } = options

    try {
      const tx = options.txHash
        ? await client.getTransactionReceipt({ hash: options.txHash })
        : null

      const data = await this.getClaimEventsAll({
        ...options,
        blockNumber: Number(tx?.blockNumber),
      })

      if (data) {
        return {
          data,
          count: Object.keys(data).length,
        }
      }
    } catch (error) {
      // do nothing
      // eslint-disable-next-line no-console
      console.warn('[App warn]: error on getClaimedMap', {
        airdropName,
        txHash,
        recipients: recipients.length,
      }, error)
    }

    try {
      const result0 = await this.getClaimedMapMulticall(airdropName, recipients)
      if (result0) return result0
    } catch (error) {
      /** do nothing */
    }

    const result2 = await this.getClaimedMapBatch(airdropName, recipients)
    return result2
  }

  private async getClaimedMapMulticall(airdropName: string, recipients: IRecipients) {
    const multicall3Service = Multicall3Service.buildClass(this.chainId)
    if (!multicall3Service || !this.contract) return null

    const results = await createClaimedMulticallByRecipients(
      multicall3Service,
      this.contract,
      recipients,
      airdropName,
    )

    let count = 0
    const isClaimedMap = recipients.reduce((acc, [recipient], index) => {
      if (!results[index]) return acc
      acc[recipient] = EMPTY_OBJECT
      count += 1
      return acc
    }, {} as Record<string, { transactionHash?: Hash }>)

    return { data: isClaimedMap, count }
  }

  private async getClaimedMapBatch(airdropName: string, recipients: IRecipients) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const promises: Promise<boolean>[] = []
    const hash = stringToHash(airdropName)

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in recipients) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        // double sleep needs for batch RPC requests
        await sleep(50)
        await sleep(50)
      }

      const [recipient] = recipients[i]
      const promise = this.contract.read.isClaimed([hash, recipient])
      promises.push(promise)
    }

    const arr = await Promise.all(promises)
    let count = 0

    const isClaimedMap = arr.reduce((acc, value, index) => {
      if (!value) return acc
      const [recipient] = recipients[index]
      acc[recipient] = EMPTY_OBJECT
      count += 1
      return acc
    }, {} as Record<string, { transactionHash?: Hash }>)

    return { data: isClaimedMap, count }
  }

  public async getClaimedMapByRecipient(recipient: Hash, airdropNames: string[]) {
    const { contract, settings } = this
    if (!contract || !settings?.address) return null

    const result0 = await this.getClaimedMapMulticallByRecipient(recipient, airdropNames)
    if (result0) return result0

    const result2 = await this.getClaimedMapBatchByRecipient(recipient, airdropNames)
    return result2
  }

  private async getClaimedMapMulticallByRecipient(recipient: Hash, airdropNames: string[]) {
    const multicall3Service = Multicall3Service.buildClass(this.chainId)
    if (!multicall3Service || !this.contract) return null

    const results = await createClaimedMulticallByAirdropNames(
      multicall3Service,
      this.contract,
      recipient,
      airdropNames,
    )

    const isClaimedMap = airdropNames.reduce((acc, airdropName, index) => {
      acc[airdropName] = results[index]
      return acc
    }, {} as Record<string, boolean>)

    return isClaimedMap
  }

  private async getClaimedMapBatchByRecipient(recipient: Hash, airdropNames: string[]) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const result: Record<string, boolean> = {}

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in airdropNames) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        // need for in one request COUNT_PER_REQUEST
        await sleep(50)
        await sleep(50)
      }

      const airdropName = airdropNames[i]
      const hash = stringToHash(airdropName)
      const isClaimed = await this.contract.read.isClaimed([hash, recipient])
      result[airdropName] = isClaimed
    }

    return result
  }


  public async getAirdropsMetaMap(airdropNames: string[]) {
    const { contract, settings } = this
    if (!contract || !settings?.address) return null

    const result0 = await this.getAirdropsMetaMapMulticall(airdropNames)
    if (result0) return result0

    const result2 = await this.getAirdropsMetaMapBatch(airdropNames)
    return result2
  }

  private async getAirdropsMetaMapMulticall(airdropNames: string[]) {
    const multicall3Service = Multicall3Service.buildClass(this.chainId)
    if (!multicall3Service || !this.contract) return null

    const initialValue: IAirdropsMeta = {}

    const results = await createAirdropsMulticall(
      multicall3Service,
      this.contract,
      airdropNames,
    )

    const airdropsMetaMap = airdropNames.reduce((acc, airdropName, index) => {
      const [, , , total, claimed, cancelled] = results[index]

      // if airdropName is broken
      if (total === BI_ZERO) {
        return acc
      }

      acc[airdropName] = {
        cancelled,
        done: claimed === total,
        claimed: claimed.toString(),
        total: total.toString(),
        percent: Number((claimed * 100n) / total),
      }

      return acc
    }, initialValue)

    return airdropsMetaMap
  }

  private async getAirdropsMetaMapBatch(airdropNames: string[]) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const result: IAirdropsMeta = {}

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in airdropNames) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        // need for in one request COUNT_PER_REQUEST
        await sleep(50)
        await sleep(50)
      }

      const airdropName = airdropNames[i]
      const hash = stringToHash(airdropName)
      const airdrop = await this.contract.read.airdrops([hash])
      const [, , , total, claimed, cancelled] = airdrop

      if (total > BI_ZERO) {
        result[airdropName] = {
          cancelled,
          done: claimed === total,
          claimed: claimed.toString(),
          total: total.toString(),
          percent: Number((claimed * 100n) / total),
        }
      }
    }

    return result
  }

  public async checkApproveAndBalanceToken(payload: CheckApproveAndBalanceTokenInput) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    // before claim check approve and balance
    const { contract: tokenContract } = ERC20TokenService.buildClass(payload.tokenAddress, this.chainId)

    if (!tokenContract) {
      throw new Error('Please init token contract')
    }

    await tokenContract.estimateGas.transferFrom(
      [payload.owner, payload.recipient, BigInt(payload.amount)],
      { account: this.contract.address },
    )

    return true
  }

  public async claim([
    airdropName,
    proof,
    amount,
    recipient,
    ethAccount,
  ]: [string, Hash[], string, Hash, Hash], feeData: FeeData, isInjectedWallet: boolean) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }
    const args = [
      proof,
      recipient,
      BigInt(amount),
      airdropName,
    ] as const

    const { rpcProvider: client, settings } = new NetworkService(this.chainId)

    if (!client) {
      throw new Error('Please init client')
    }

    const data = encodeFunctionData({
      args,
      abi: MerkleTree,
      functionName: 'claim',
    })

    const overrides = { account: ethAccount }

    const { gasLimit, gasParams } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.claim(args, overrides),
      settings?.network.opStack ? estimateL1Fee(client, {
        data,
        ...overrides,
        to: this.contract.address,
      }) : Promise.resolve(BI_ZERO),
      feeData,
      client,
      ethAccount,
      isInjectedWallet,
    )

    return {
      ...gasParams,
      data,
      gas: gasLimit,
      to: this.contract.address,
    }
  }

  public async cancelAirdrop(
    airdropName: string,
    ethAccount: Hash,
    feeData: FeeData,
    isInjectedWallet: boolean,
  ) {
    if (!this.contract) {
      throw new Error(NOT_DEPLOYED_CONTRACT_ERROR)
    }

    const { rpcProvider: client, settings } = new NetworkService(this.chainId)

    if (!client) {
      throw new Error('Please init client')
    }

    const data = encodeFunctionData({
      abi: MerkleTree,
      args: [airdropName],
      functionName: 'cancelAirdrop',
    })

    const overrides = { account: ethAccount }

    const { gasLimit, gasParams } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.cancelAirdrop([airdropName], overrides),
      settings?.network.opStack ? estimateL1Fee(client, {
        data,
        ...overrides,
        to: this.contract.address,
      }) : Promise.resolve(BI_ZERO),
      feeData,
      client,
      ethAccount,
      isInjectedWallet,
    )


    return {
      ...gasParams,
      data,
      gas: gasLimit,
      to: this.contract.address,
    }
  }
}
