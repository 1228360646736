import type {
  Context2,
  ComposableFn,
} from 'vuex-smart-module/lib'

import type { Store } from 'vuex'

import {
  Getters,
  Mutations,
  Actions,
  Module,
  createComposable,
} from 'vuex-smart-module'

import {
  NetworkChainId,
  NetworkService,
} from '@/services'

import {
  requestPolling,
  sleep,
} from '@/utils'

import {
  POLLING_BALANCE_TIMEOUT,
} from '@/utils/constants/params'

import {
  createSetState,
} from './types'

import { AirdropStoreModule } from './airdrop'
import { MultisenderStoreModule } from './multisender'
import { WalletStoreModule } from './wallet'
import { Hash } from 'viem'


type S = AppState
type G = AppGetters
type M = AppMutations
type A = AppActions

export class AppState {
  public chainId = 1

  public balance = ''

  public isLoadingBalance = false

  public rpcSettings: NetworkService['rpcSettings'] = []
}

class AppGetters extends Getters<S> {
  private airdropStore!: Context2<typeof AirdropStoreModule>

  private multisenderStore!: Context2<typeof MultisenderStoreModule>

  private walletStore!: Context2<typeof WalletStoreModule>

  public $init(store: Store<unknown>) {
    this.airdropStore = AirdropStoreModule.context(store) as Context2<typeof AirdropStoreModule>
    this.multisenderStore = MultisenderStoreModule.context(store) as Context2<typeof MultisenderStoreModule>
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>
  }

  public get isSupportedNetwork() {
    const { chainId } = this.walletStore.state
    const { isConnected } = this.walletStore.getters

    if (!chainId || typeof chainId === 'string' || !isConnected) return true

    const networkService = new NetworkService(chainId)
    const address = networkService.settings?.contracts.multisenderMerkle.address

    return !!address
  }

  public get isMismatchWalletNetwork() {
    const { chainId } = this.walletStore.state
    if (!chainId) return false
    return this.state.chainId !== chainId
  }

  public get isMismatchMultisenderNetwork() {
    const { chainId } = this.multisenderStore.state
    const { isConnected } = this.walletStore.getters
    if (!isConnected) return false

    return !!(
      chainId
      && this.state.chainId !== chainId
    )
  }

  public get isMismatchAirdropNetwork() {
    const { chainId } = this.airdropStore.state
    const { isConnected } = this.walletStore.getters

    if (!isConnected) return false

    return (
      chainId
      && chainId !== this.state.chainId
    )
  }

  public get networkSettings() {
    const { chainId } = this.state
    const networkService = new NetworkService(chainId)
    return networkService.settings
  }

  public get networkIcon() {
    const { currency, network } = this.getters.networkSettings || {}

    return (currency?.icon || network?.icon)
  }

  public get networkName() {
    const { network } = this.getters.networkSettings || {}
    return network?.name
  }

  public get networkShortName() {
    const { network } = this.getters.networkSettings || {}
    return network?.nameShort
  }

  public get activeRpc() {
    const { rpcSettings, chainId } = this.state
    return rpcSettings.find((el) => el.active) || new NetworkService(chainId).rpcSettings[0]
  }
}

class AppMutations extends Mutations<S> {
  public setState(options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) {
    // @ts-ignore: TODO ts(2322)
    this.state[options.k] = options.v
  }
}

export class AppActions extends Actions<S, G, M, A> {
  private walletStore!: Context2<typeof WalletStoreModule>

  public async $init(store: Store<unknown>) {
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>

    await sleep(1000)
    void this.actions.updateRpc()
    requestPolling(() => this.actions.updateBalance(), POLLING_BALANCE_TIMEOUT)
  }

  public switchToNetwork(chainId?: number | null) {
    const setState = createSetState<A>(this)
    const supportedNetworks = NetworkService.getAllSupportedNetworks()

    if (!chainId) {
      const [first] = Object.values(supportedNetworks)
      setState({ k: 'chainId', v: first.network.chainId })
    } else if (!supportedNetworks[chainId as NetworkChainId]) {
      // do not switch to unknown network
    } else {
      setState({ k: 'chainId', v: chainId })
    }

    void this.actions.updateRpc()
    void this.actions.updateBalance()
  }

  public async updateBalance(address?:Hash) {
    const { ethAccount, chainId } = this.walletStore.state
    const walletAddress = address || ethAccount
    if (!walletAddress || typeof chainId === 'string') return
    const setState = createSetState<A>(this)

    const { rpcProvider } = new NetworkService(this.state.chainId)

    try {
      setState({ k: 'isLoadingBalance', v: true })
      const balance = await rpcProvider?.getBalance?.({ address: walletAddress })
      setState({ k: 'balance', v: balance?.toString() || '' })
    } finally {
      setState({ k: 'isLoadingBalance', v: false })
    }
  }

  public updateRpc() {
    const { chainId } = this.state
    if (!chainId) return
    const setState = createSetState<A>(this)

    const { rpcSettings } = new NetworkService(chainId)
    setState({ k: 'rpcSettings', v: rpcSettings })
  }
}

const storeModule = new Module({
  namespaced: true,
  state: AppState,
  getters: AppGetters,
  mutations: AppMutations,
  actions: AppActions,
})

const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as AppStoreModule,
  useStore as useAppStoreModule,
}
