

const version = '0.11'
export class StorageService<T> {
  public readonly key: string

  constructor(
    private baseKey: string,
    prefix = '',
  ) {
    this.key = prefix ? `${prefix}-${baseKey}` : baseKey

    const _version = localStorage.getItem(`${this.key}-version`)

    if (_version !== version) {
      localStorage.removeItem(this.key)
      localStorage.setItem(`${this.key}-version`, version)
    }
  }

  public getKey(id?: string | number | null) {
    const { key } = this
    return (id === void 0 || id === null || id === '')
      ? key
      : `${key}[${id}]`
  }

  public get<TT = T>(id?: number | string, defaults?: TT) {
    const key = this.getKey(id)
    const str = localStorage.getItem(key)

    if (defaults !== void 0 && str === null) return defaults
    if (!str) return null

    try {
      return JSON.parse(str) as TT
    } catch {
      return str as unknown as TT
    }
  }

  public set<TT = T>(data: TT, id?: number | string) {
    const key = this.getKey(id)

    if (typeof data === 'object') {
      const json = JSON.stringify(data)
      localStorage.setItem(key, json)
    } else {
      localStorage.setItem(key, data as unknown as string)
    }

    return this
  }

  public remove(id?: number | string) {
    const key = this.getKey(id)
    localStorage.removeItem(key)
    return this
  }
}
