import { Hash } from 'viem'
import pickBy from 'lodash/pickBy'
import isEqual from 'lodash/isEqual'
import { getEnsAddress } from 'viem/actions'

import { RpcTypes, storages } from '@/services'

import { NetworkChainId } from './types'
import type { INetworkConfig, RpcInfo } from './types'

import { NETWORK_CONFIGS, NETWORK_MAINNET } from './network-configs'
import {
  isLink,
  getRpcProvider,
  getSingleRpcProvider,
  getStaticRpcProviderMemoized,
} from './utils'


export class NetworkService {
  public readonly settings?: INetworkConfig

  public static getAllNetworks() {
    return NETWORK_CONFIGS
  }

  public static getAllSupportedNetworks() {
    return pickBy(NETWORK_CONFIGS, (value) => (
      value.contracts.multisenderMerkle.address
    ))
  }

  public static getNetworkForSwitch(chainId?: NetworkChainId | string | null) {
    if (!chainId || typeof chainId === 'string') {
      return null
    }

    const settings = NETWORK_CONFIGS[chainId]

    if (!settings) {
      return null
    }

    return {
      chainId: `0x${settings.network.chainId.toString(16)}`,
      chainName: settings.network.name,
      rpcUrls: settings.rpc.urls,
      blockExplorerUrls: ([] as string[]).concat(settings.explorer.url),
      nativeCurrency: {
        name: settings.currency.name,
        symbol: settings.currency.symbol,
        decimals: settings.currency.decimals,
      },
    }
  }

  public static getRpcProvider(rpcUrls?: string[], chainId?: number) {
    return getRpcProvider(rpcUrls, chainId)
  }

  public static getSingleRpcProvider(rpcUrl?: string, chainId?: number) {
    return getSingleRpcProvider(rpcUrl, chainId)
  }

  public static resolveAddress(name: string): Promise<Hash | string | null> | Hash | string {
    const { rpc, network } = NETWORK_MAINNET
    if (!rpc?.urls || !network?.chainId) {
      return name
    }

    const client = getRpcProvider(rpc.urls, network.chainId)
    if (!client) {
      return name
    }

    return getEnsAddress(client, { name })
  }

  constructor(
    public readonly chainId: NetworkChainId,
  ) {
    this.settings = NETWORK_CONFIGS[chainId]
  }

  public get rpcSettings(): RpcInfo[] {
    if (!this.settings) return []

    const { urls } = this.settings.rpc
    const rpcUrls = storages.rpcUrls.get() || {}

    const customUrls = rpcUrls[this.chainId] ?? []

    return [{
      urls,
      label: 'form.default-rpc-label',
      type: RpcTypes.DEFAULT,
      active: customUrls.length === 0,
    },
    {
      urls: customUrls,
      label: 'form.custom-rpc-label',
      type: RpcTypes.CUSTOM,
      active: customUrls.length > 0,
    }]
  }

  public get activeRpcUrls() {
    return this.rpcSettings?.find((el) => el.active)?.urls || []
  }

  public get rpcProvider() {
    return getStaticRpcProviderMemoized(this.activeRpcUrls, this.chainId)
  }

  public get explorer() {
    const chain = NETWORK_CONFIGS[this.chainId]
    return chain.explorer.url
  }

  public getExplorerLink(
    address: Hash,
    type: 'tx' | 'token' | 'address' | 'block',
    chainId = this.chainId,
  ) {
    const { explorer } = NETWORK_CONFIGS[chainId]
    const prefix = explorer[type] || type

    return [
      isLink(prefix) ? void 0 : explorer.url,
      prefix,
      address,
    ].filter(Boolean).join('/')
  }

  public setRpc(rpc: RpcInfo) {
    const rpcUrls = storages.rpcUrls.get(void 0) || {}

    if (isEqual(rpc.urls, this.settings?.rpc.urls)) {
      delete rpcUrls[this.chainId]
    } else {
      rpcUrls[this.chainId] = rpc.urls
    }

    storages.rpcUrls.set(rpcUrls)
  }
}
