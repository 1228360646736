import { useAppStore } from '@/composable'
import { ValidatorService } from '@/services'

export function useCheckRpc() {
  const { $state } = useAppStore()

  return async (urls: string[]) => {
    const validatorService = new ValidatorService()

    const promises = urls.map((url) => validatorService
      .rpcUrl(url, $state.chainId)
      .then((result) => (result === true ? result : result.message)))

    const results = await Promise.allSettled(promises)

    const failedResults = results.filter((result) => result.status === 'rejected' || result.value !== true)
    if (failedResults.length < promises.length) {
      return true
    }

    const result = failedResults[0]
    return result.status === 'rejected' ? result.reason : result.value
  }
}
