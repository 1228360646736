import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MMessage = _resolveComponent("MMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$s.root)
  }, [
    _createVNode(_component_MMessage, {
      type: "danger",
      action: "",
      "action-mobile-to-bottom": "",
      text: _ctx.$t('rpcToast.error-message'),
      "action-text": _ctx.$t('rpcToast.button-action'),
      onAction: _ctx.onOpenProfile
    }, null, 8, ["text", "action-text", "onAction"])
  ], 2))
}