import {
  ValidatorServiceBase,
} from './types'

import {
  addressRequiredRule,
  addressValidRule,
  ERC20Rule,
  urlRule,
  emailRule,
  websiteRule,
  websiteProtocolRule,
  twitterLoginRule,
  twitterAllowedSymbolsRule,
  telegramLoginRule,
  telegramAllowedSymbolsRule,
  contactsEmptyRule,
  descriptionEmptyRule,

  recipientsShortRule,
  recipientsManyColumnsRule,
  recipientsWithAmountFormatRule,
  recipientsWithAddressFormatRule,
  recipientsWithEnsAddressFormatRule,
  recipientsWithAddressDuplicateRule,

  rpcChainIdCorrectedRule,
} from './rules'


export class ValidatorService extends ValidatorServiceBase {
  public async ERC20Address(
    address: string | null,
    chainId: number,
    isHandleChange?: boolean,
  ) {
    const isAddressExist = await this.executor(addressRequiredRule, address)
    if (isAddressExist !== true) return isHandleChange ? true : isAddressExist

    const isValidAddress = await this.executor(addressValidRule, address || '')

    if (isValidAddress !== true) return isValidAddress

    const isErc20 = await this.executor(ERC20Rule, address || '', chainId)
    if (isErc20 !== true) return isErc20

    return true
  }

  public async recipients(
    list: [string, string][] | null | undefined,
    minimal: number,
    decimals = 18,
  ) {
    const result0 = await this.executor(recipientsShortRule, list, minimal)
    if (result0 !== true) return result0

    const result1 = await this.executor(recipientsWithAmountFormatRule, list || [], decimals)
    if (result1 !== true) return result1

    const result2 = await this.executor(recipientsManyColumnsRule, list || [], minimal)
    if (result2 !== true) return result2

    const result3 = await this.executor(recipientsWithAddressFormatRule, list || [])
    if (result3 !== true) return result3

    const result4 = await this.executor(recipientsWithEnsAddressFormatRule, list || [])
    if (result4 !== true) return result4

    const result5 = await this.executor(recipientsWithAddressDuplicateRule, list || [])
    if (result5 !== true) return result5

    return true
  }

  public async rpcUrl(
    url: string | void,
    chainId: number | null | void,
  ) {
    const result0 = await this.executor(urlRule, url)
    if (result0 !== true) return result0

    const result2 = await this.executor(rpcChainIdCorrectedRule, url || void 0, chainId || void 0)

    if (result2 !== true) return result2

    return true
  }

  public async airdropContacts(data: {
    email: string;
    website: string;
    twitter: string;
    telegram: string;
    description: string;
  }) {
    const errors = []

    if (data.email?.trim()) {
      const result0 = await this.executor(emailRule, data.email)
      if (result0 !== true) errors.push({ ...result0, type: 'email' as const })
    }

    if (data.website?.trim()) {
      let result = true

      if (result === true) {
        const result0 = await this.executor(websiteProtocolRule, data.website)

        if (result0 !== true) {
          errors.push({ ...result0, type: 'website' as const })
          result = false
        }
      }

      if (result === true) {
        const result1 = await this.executor(websiteRule, data.website)
        if (result1 !== true) {
          errors.push({ ...result1, type: 'website' as const })
          result = false
        }
      }
    }

    if (data.twitter?.trim()) {
      let result = true

      if (result === true) {
        const result0 = await this.executor(twitterLoginRule, data.twitter)
        if (result0 !== true) {
          errors.push({ ...result0, type: 'twitter' as const })
          result = false
        }
      }

      if (result === true) {
        const result0 = await this.executor(twitterAllowedSymbolsRule, data.twitter)
        if (result0 !== true) {
          errors.push({ ...result0, type: 'twitter' as const })
          result = false
        }
      }
    }

    if (data.telegram?.trim()) {
      let result = true

      if (result === true) {
        const result0 = await this.executor(telegramLoginRule, data.telegram)
        if (result0 !== true) {
          errors.push({ ...result0, type: 'telegram' as const })
          result = false
        }
      }

      if (result === true) {
        const result0 = await this.executor(telegramAllowedSymbolsRule, data.telegram)
        if (result0 !== true) {
          errors.push({ ...result0, type: 'telegram' as const })
          result = false
        }
      }
    }

    const result0 = await this.executor(contactsEmptyRule, data)
    if (result0 !== true) {
      errors.push({ ...result0, type: 'contacts' as const })
    }

    const result1 = await this.executor(descriptionEmptyRule, data.description)
    if (result1 !== true) {
      errors.push({ ...result1, type: 'description' as const })
    }

    return errors.length ? errors : true
  }
}
