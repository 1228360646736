import { Chain, Hash } from 'viem'

export enum NetworkChainId {
  mainnet = 1,
  sepolia = 11155111,
  optimism = 10,
  cronos = 25,
  binance = 56,
  xdai = 100,
  heco = 128,
  polygon = 137,
  fantom = 250,
  moonbeam = 1284,
  moonriver = 1285,
  iotex = 4689,
  arbitrum = 42161,
  celo = 42220,
  avalanche = 43114,
  harmony = 1666600000,
  base = 8453,
}

export enum GetTokensApis {
  ETHPLORER = 'ethplorer',
  BLOCKSCOUT = 'blockscout',
  MULTISENDER = 'multisender',
  ETHERSCAN = 'etherscan',
  ALCHEMY = 'alchemy',
}

export enum RpcTypes {
  CUSTOM = 'custom',
  DEFAULT = 'default',
}

export type TokenApi = {
  type: GetTokensApis;
  url: string;
  query: string;
}

export type INetworkConfig = Readonly<{
  chain: Chain;
  network: {
    chainId: NetworkChainId;
    name: string;
    nameShort?: string;
    icon?: string;
    color?: string;
    pollTime: number;
    // флаг для определения стратегии подсчета fee / london fork
    EIP1559: boolean;
    opStack?: boolean;
    // флаг тестовой сети
    testnet: boolean;
  };
  currency: {
    name: string;
    symbol: string;
    decimals: number;
    icon: string;
  };
  contracts: {
    multisenderMerkle:
      | {
          address: Hash;
          blockFrom: number;
        }
      | {
          address: null;
          blockFrom?: never;
        };
    multicall3?: {
      address: Hash;
    };
  };
  rpc: {
    retryAttempt: number;
    urls: string[];
  };
  explorer: {
    url: string;
    tx?: string;
    address?: Hash;
    token?: Hash;
    block?: string;
  };
  tokenApis: TokenApi[];
  blockGasLimit: number;
}>;

export type RpcInfo = {
  urls: string[];
  label: string;
  active: boolean;
  type: RpcTypes;
}
