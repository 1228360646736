import memoize from 'lodash/memoize'
import { http, createPublicClient, fallback } from 'viem'

import { NETWORK_CONFIGS, NetworkChainId } from '@/services'


export const isLink = (text: string) => /http(s)?:\/\//.test(text)

export const getSingleRpcProvider = (url?: string, chainId?: number) => {
  if (!chainId || !url) {
    return undefined
  }
  const publicClient = createPublicClient({
    transport: http(url),
    chain: NETWORK_CONFIGS[chainId as NetworkChainId].chain,
  })

  return publicClient
}

export const getRpcProvider = (urls?: string[], chainId?: number) => {
  if (!chainId || !urls?.length) {
    return undefined
  }

  if (urls.length > 1) {
    const publicClient = createPublicClient({
      transport: fallback(urls.map((url) => http(url))),
      chain: NETWORK_CONFIGS[chainId as NetworkChainId].chain,
    })

    return publicClient
  }
  return getSingleRpcProvider(urls[0], chainId)
}

export const getStaticRpcProviderMemoized = memoize(getRpcProvider)
