import { fallback } from 'viem'
import {
  bsc,
  base,
  sepolia,
  mainnet,
  polygon,
  arbitrum,
  optimism,
  avalanche,
} from 'viem/chains'
import { http, createConfig, WagmiPlugin } from '@wagmi/vue'
import {
  safe,
  injected,
  walletConnect,
  coinbaseWallet,
} from '@wagmi/connectors'

import { IInitPlugin } from './types.d'

import { NetworkChainId, NetworkService } from '@/services'


const APP_NAME = process.env.VUE_APP_NAME
// const SITE_URL = process.env.VUE_APP_SITE_ORIGIN
const APP_LOGO_URL = `${process.env.VUE_APP_SITE_ORIGIN}/favicon/apple-touch-icon.png`

function getFallbackProvider(chain: NetworkChainId) {
  return fallback(new NetworkService(chain).activeRpcUrls.map((url) => http(url)))
}

export const getConfig = () => createConfig({
  chains: [
    mainnet,
    bsc,
    base,
    arbitrum,
    avalanche,
    polygon,
    optimism,
    sepolia,
  ],
  connectors: [
    safe({
      allowedDomains: [/app.safe.global$/],
    }),
    injected(),
    walletConnect({
      projectId: 'e346ca1620ff47bf7f90af576f5c5082',
      showQrModal: true,
      metadata: {
        url: window.location.href, // SITE_URL,
        name: APP_NAME,
        description: APP_NAME,
        icons: [APP_LOGO_URL],
      },
    }),
    coinbaseWallet({
      appName: APP_NAME,
    }),
  ],
  transports: {
    [base.id]: getFallbackProvider(NetworkChainId.base),
    [bsc.id]: getFallbackProvider(NetworkChainId.binance),
    [mainnet.id]: getFallbackProvider(NetworkChainId.mainnet),
    [polygon.id]: getFallbackProvider(NetworkChainId.polygon),
    [sepolia.id]: getFallbackProvider(NetworkChainId.sepolia),
    [arbitrum.id]: getFallbackProvider(NetworkChainId.arbitrum),
    [optimism.id]: getFallbackProvider(NetworkChainId.optimism),
    [avalanche.id]: getFallbackProvider(NetworkChainId.avalanche),
  },
})

export const initPlugin: IInitPlugin = (app) => {
  app.use(WagmiPlugin, { config: getConfig() })
}
